import { Box, FormHelperText, FormLabel, Grid, IconButton, OutlinedInput } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import ButtonCancel from "../UI/ButtonCancel";
import ButtonSave from "../UI/ButtonSave";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "../UI/Button";
import InputImageByte from "../UI/InputImageByte";
import { toast } from "react-toastify";
import { errorMessage } from "../util/ConfirmAlertUtil";
import { removeUploadedImagem, uploadImagem } from "./api-certificado-borda";
import { confirmAlert } from "react-confirm-alert";

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const CertificadoBordaForm = ({ certificadoBorda, setCertificadoBorda, handleSave, handleRefresh }) => {
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (file) {
      // create the preview
      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);

      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [file]);

  const handleOnChange = (event) => {
    setCertificadoBorda((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleCancel = () => {
    setCertificadoBorda(null);
  };


  const handleUploadLogoInit = () => {
    if (file === null) {
      return;
    }

    const fileFormData = new FormData();
    fileFormData.append("file", file);
    handleUploadImagem(fileFormData);
  };


  const handleChangeImage = (e) => {
    setFile(e.target.files[0]);
  };

  const handleRemoveUploadedImagem = () => {
    confirmAlert({
      title: "Alerta",
      message: "Deseja deletar esta imagem?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            removeUploadedImagem(certificadoBorda.codigo).then((data) => {
              if (data) {
                if (data.error) {
                  errorMessage(data.message);
                } else {
                  setCertificadoBorda(data);
                  handleRefresh();
                  toast.success("Operação Realizada com Sucesso.");
                }
              }
            });
          },
        },
        {
          label: "Não",
          onClick: () => { },
        },
      ],
    });
  };

  const handleUploadImagem = (formData) => {
    setIsLoadingAction(true);
    uploadImagem(certificadoBorda.codigo, formData).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setCertificadoBorda(data);
          handleRefresh();
          toast.success("Operação Realizada com Sucesso.");
        }
      }
      setIsLoadingAction(false);
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <IconButton onClick={handleCancel}>
          <CancelIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <FormGrid item xs={4}>
          <FormLabel htmlFor="descricao" required>
            Descricao
          </FormLabel>
          <OutlinedInput
            required
            id="descricao"
            name="descricao"
            type="name"
            value={certificadoBorda.descricao ? certificadoBorda.descricao : ""}
            onChange={handleOnChange}
            placeholder="Descrição"
          />
        </FormGrid>
      </Grid>

      {certificadoBorda.eNovo === null || !certificadoBorda.eNovo ?
        <Grid item xs={12}>
          <Box component="section" sx={{ p: 2, border: "1px dashed grey" }}>
            <FormGrid item xs={4}>
              <FormLabel>Certificado Borda - Image</FormLabel>
              {certificadoBorda !== null && certificadoBorda.urlImagem !== null &&
                certificadoBorda.urlImagem !== "" ? (
                <>
                  <img
                    style={{
                      width: "200px",
                    }}
                    src={certificadoBorda.urlImagem}
                    alt=""
                  />
                  <Button
                    isLoadingAction={isLoadingAction}
                    sx={{ mt: 2 }} onClick={handleRemoveUploadedImagem}>
                    Remover Imagem
                  </Button>
                </>
              ) : (
                <>
                  <FormLabel htmlFor="documentFile">
                    Selecione a Imagem
                  </FormLabel>

                  <InputImageByte
                    id="documentFile"
                    name="documentFile"
                    image={preview}
                    handleChangeImage={handleChangeImage}
                    multiple={false}
                  />
                  {file && (
                    <Button
                      sx={{ mt: 2 }}
                      disabled={isLoadingAction}
                      onClick={handleUploadLogoInit}
                    >
                      Upload Logo
                    </Button>
                  )}
                </>
              )}

            </FormGrid>
            <FormHelperText>
              <span style={{ fontWeight: 'bold' }}>Atenção: </span>A medida da Imagem deve ser de 842x595 px.
            </FormHelperText>
          </Box>
        </Grid> :
        <></>
      }


      <FormGrid item xs={12}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <ButtonCancel handleCancel={handleCancel} />
          </Grid>
          <Grid item>
            <ButtonSave handleSave={handleSave} />
          </Grid>
        </Grid>
      </FormGrid>
    </Grid>
  );
};

export default CertificadoBordaForm;
