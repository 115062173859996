import { SERVER_URL } from '../../constants';
import { fetchGetAuth, fetchPostAuth, fetchPostAuthFile, fetchRemoveAuthReturn } from '../../util/FetchUtil';

const controller = 'certificado-borda';

export const pageCertificadoBorda = (pageNumber, size) => {
  const url = SERVER_URL + controller + '/query-page?page=' + pageNumber + '&size=' + size;
  return fetchGetAuth(url);
}

export const removeCertificadoBordaByCodigo = (codigo) => {
  const url = SERVER_URL + controller + "/" + codigo;
  return fetchRemoveAuthReturn(url);
}

export const saveCertificadoBorda = (categoria) => {
  const url = SERVER_URL + controller + "/save";
  return fetchPostAuth(url, categoria);
}

export const uploadImagem = (codigo, formData) => {
  const url = SERVER_URL + controller + '/upload-imagem/' + codigo;
  return fetchPostAuthFile(url, formData);
}

export const removeUploadedImagem = (codigo) => {
  const url = SERVER_URL + controller + "/remove-uploaded-imagem/" + codigo;
  return fetchRemoveAuthReturn(url);
}