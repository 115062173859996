import { Dialog, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { errorMessage } from "../util/ConfirmAlertUtil";
import { pageCertificadoBorda } from "./api-certificado-borda";
import CertificadoBordaTableSelectView from "./CertificadoBordaTableSelectView";

const CertificadoBordaTableSelectDialog = ({ setCertificadoBorda, openDialog, handleCloseDialog }) => {
  const [listCertificadoBorda, setListCertificadoBorda] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [size, setSize] = useState(10);

  useEffect(() => {
    fetchCertificadoBorda(0);
    // eslint-disable-next-line
  }, []);


  const handleCertificadoBordaChangePage = (newPage) => {
    fetchCertificadoBorda(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
  };

  const fetchCertificadoBorda = (newPage) => {
    pageCertificadoBorda(newPage, size).then((data) => {
      setIsLoading(true);
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListCertificadoBorda(data);
        }
      }
      setIsLoading(false);
    });
  };


  return (
    <Dialog onClose={handleCloseDialog} open={openDialog}>
      <Grid container spacing={3} sx={{ p: 3 }}>
        <Grid item xs={12}>
          <CertificadoBordaTableSelectView
            listCertificadoBorda={listCertificadoBorda}
            setCertificadoBorda={setCertificadoBorda}
            handleChangePage={handleCertificadoBordaChangePage}
            isLoading={isLoading}
            size={size}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>

      </Grid>
    </Dialog>
  );
};

export default CertificadoBordaTableSelectDialog;
