import React from "react";
import { Route, Routes } from "react-router-dom";
import Signin2 from "./auth/Sigin2";
import NotFoundError from "./components/NotFoundError";
import Home from "./components/Home";
import LateralMenuSkeleton2 from "./shared/components/LateralMenu/LateralMenuSkeleton2";
import PrivateRoute from "./auth/PrivateRoute";
import CategoriaContainer from "./components/Categoria/CategoriaContainer";
import EventoContainer from "./components/Evento/EventoContainer";
import GeneroContainer from "./components/Genero/GeneroContainer";
import ParticipanteContainer from "./components/Participante/ParticipanteContainer";
import EventoDataContainer from "./components/Evento/EventoData/EventoDataContainer";
import ConfiguracaoContainer from "./components/Configuracao/ConfiguracaoContainer";
import EventoDataCategoriaContainer from "./components/Evento/EventoData/EventoDataCategoria/EventoDataCategoriaContainer";
import CoreografiaContainer from "./components/Coreografia/CoreografiaContainer";
import CoreografiaParticipantePerfilContainer from "./components/CoreografiaParticipantePerfil/CoreografiaParticipantePerfilContainer";
import CoreografiaPagamentoContainer from "./components/CoreografiaPagamento/CoreografiaPagamentoContainer";
import ApresentacaoContainer from "./components/Apresentacao/ApresentacaoContainer";
import MidiaContainer from "./components/Midia/MidiaContainer";
import EventoDataJuradoContainer from "./components/Evento/EventoData/EventoDataJurado/EventoDataJuradoContainer";
import UsuarioPerfilContainer from "./components/Usuario/UsuarioPerfil/UsuarioPerfilContainer";
import EventoCoreografiaPagamentoContainer from "./components/EventoCoreografiaPagamento/EventoCoreografiaPagamentoContainer";
import UsuarioFormSenha from "./components/Usuario/UsuarioFormSenha";
import RequestResetPassword from "./auth/RequestResetPassword";
import ResetPassword from "./auth/ResetPassword";
import ApresentacaoTableSimplificada from "./components/Apresentacao/ApresentacaoTableSimplificado";
import IndicacaoContainer from "./components/Indicacao/IndicacaoContainer";
import ApresentacaoJuradoContainer from "./components/Apresentacao/ApresentacaoJurado/ApresentacaoJuradoContainer";
import CoreografiaAgenciaEventoContainer from "./components/CoreografiaAgenciaEvento/CoreografiaAgenciaEventoContainer";
import InstituicaoFormCreate from "./components/Instituicao/InstituicaoFormCreate";
import ContainerSignIn from "./auth/ContainerSignIn";
import CertificadoBordaContainer from "./components/CertificadoBorda/CertificadoBordaContainer";

export const MenuRouter = () => {
  return (
    // <Routes>
    <Routes>
      {/* <Route exact path="/" element={<Signin2 />} /> */}
      <Route exact path="/" element={<ContainerSignIn />} />
      <Route exact path="/novo-cadastro" element={<InstituicaoFormCreate />} />
      <Route exact path="/signin" element={<Signin2 />} />

      <Route
        exact
        path="/home"
        element={
          <PrivateRoute>
            <LateralMenuSkeleton2>
              <Home />
            </LateralMenuSkeleton2>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/categoria"
        element={
          <PrivateRoute>
            <LateralMenuSkeleton2>
              <CategoriaContainer />
            </LateralMenuSkeleton2>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/certificado-borda"
        element={
          <PrivateRoute>
            <LateralMenuSkeleton2>
              <CertificadoBordaContainer />
            </LateralMenuSkeleton2>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/evento"
        element={
          <PrivateRoute>
            <LateralMenuSkeleton2>
              <EventoContainer />
            </LateralMenuSkeleton2>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/genero"
        element={
          <PrivateRoute>
            <LateralMenuSkeleton2>
              <GeneroContainer />
            </LateralMenuSkeleton2>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/participante"
        element={
          <PrivateRoute>
            <LateralMenuSkeleton2>
              <ParticipanteContainer />
            </LateralMenuSkeleton2>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/evento-data"
        element={
          <PrivateRoute>
            <LateralMenuSkeleton2>
              <EventoDataContainer />
            </LateralMenuSkeleton2>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/evento-data-categoria"
        element={
          <PrivateRoute>
            <LateralMenuSkeleton2>
              <EventoDataCategoriaContainer />
            </LateralMenuSkeleton2>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/configuracao"
        element={
          <PrivateRoute>
            <LateralMenuSkeleton2>
              <ConfiguracaoContainer />
            </LateralMenuSkeleton2>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/coreografia"
        element={
          <PrivateRoute>
            <LateralMenuSkeleton2>
              <CoreografiaContainer />
            </LateralMenuSkeleton2>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/coreografia-agencia-evento"
        element={
          <PrivateRoute>
            <LateralMenuSkeleton2>
              <CoreografiaAgenciaEventoContainer />
            </LateralMenuSkeleton2>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/coreografia-nota-instituicao"
        element={
          <PrivateRoute>
            <LateralMenuSkeleton2>
            </LateralMenuSkeleton2>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/coreografia-participante"
        element={
          <PrivateRoute>
            <LateralMenuSkeleton2>
              <CoreografiaParticipantePerfilContainer />
            </LateralMenuSkeleton2>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/coreografia-pagamento"
        element={
          <PrivateRoute>
            <LateralMenuSkeleton2>
              <CoreografiaPagamentoContainer />
            </LateralMenuSkeleton2>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/apresentacao"
        element={
          <PrivateRoute>
            <LateralMenuSkeleton2>
              <ApresentacaoContainer />
            </LateralMenuSkeleton2>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/apresentacao-jurado"
        element={
          <PrivateRoute>
            <LateralMenuSkeleton2>
              <ApresentacaoJuradoContainer />
            </LateralMenuSkeleton2>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/apresentacao-simplificado"
        element={
          <PrivateRoute>
            <LateralMenuSkeleton2>
              <ApresentacaoTableSimplificada />
            </LateralMenuSkeleton2>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/evento-coreografia-pagamento"
        element={
          <PrivateRoute>
            <LateralMenuSkeleton2>
              <EventoCoreografiaPagamentoContainer />
            </LateralMenuSkeleton2>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/indicacao"
        element={
          <PrivateRoute>
            <LateralMenuSkeleton2>
              <IndicacaoContainer />
            </LateralMenuSkeleton2>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/request-reset-password"
        element={
          <RequestResetPassword />
        }
      />
      <Route
        exact
        path="/reset-password/:codResetPassword"
        element={
          <ResetPassword />
        }
      />
      <Route
        exact
        path="/usuario-senha"
        element={
          <PrivateRoute>
            <LateralMenuSkeleton2>
              <UsuarioFormSenha />
            </LateralMenuSkeleton2>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/midia"
        element={
          <PrivateRoute>
            <LateralMenuSkeleton2>
              <MidiaContainer />
            </LateralMenuSkeleton2>
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/evento-data-jurado"
        element={
          <PrivateRoute>
            <LateralMenuSkeleton2>
              <EventoDataJuradoContainer />
            </LateralMenuSkeleton2>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/usuario-perfil"
        element={
          <PrivateRoute>
            <LateralMenuSkeleton2>
              <UsuarioPerfilContainer />
            </LateralMenuSkeleton2>
          </PrivateRoute>
        }
      />
      <Route
        path="*"
        element={
          <LateralMenuSkeleton2>
            <NotFoundError />
          </LateralMenuSkeleton2>
        }
      />
    </Routes>
  );
};
