import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { errorMessage } from "../util/ConfirmAlertUtil";
import {
  findByEventoDataCategoriaCodigo,
  findCoreografiaByCodigo,
  saveCoreografia,
} from "../Coreografia/api-coreografia";
import { page } from "../Evento/api-evento";
import EventoTableSelectView from "../Evento/EventoTableSelectView";
import { findByEventoCodigo } from "../Evento/EventoData/EventoDataCategoria/api-evento-data-categoria";
import EventoProfile from "../Evento/EventoProfile";
import EventoDataCategoriaProfile from "../Evento/EventoData/EventoDataCategoria/EventoDataCategoriaProfile";
import EventoDataCategoriaTableSelectViewVersion2 from "../Evento/EventoData/EventoDataCategoria/EventoDataCategoriaTableSelectViewVersion2";
import CoreografiaTableSelectView from "../Coreografia/CoreografiaTableSelectView";
import CoreografiaProfile from "../Coreografia/CoreografiaProfile";
import CoreografiaAgenciaEventoMenu from "./CoreografiaAgenciaEventoMenu";
import CoreografiaParticipantePerfilTableView from "../CoreografiaParticipantePerfil/CoreografiaParticipantePerfilTableView";
import { findByCoreografiaCodigo } from "../CoreografiaParticipantePerfil/api-coreografia-participante-perfil";
import CoreografiaPagamentoTableView from "../CoreografiaPagamento/CoreografiaPagamentoTableView";
import { findByCoreografiaCodigo as findCoreografiaPagamentoByCoreografiaCodigo } from '../CoreografiaPagamento/api-coreografia-pagamento';
import CoreografiaNotaAudioTableView from "../CoreografiaNotaAudio/CoreografiaNotaAudioTableView";
import { findCoreografiaNotaListAudioByCoreografiaCodigo } from "../CoreografiaNotaAudio/api-coreografia-nota-audio";
import CoreografiaNotaPremiacao from "../Coreografia/CoreografiaNotaPremiacao";
import { toast } from "react-toastify";
import { generateNotaFinalPorCoreografia, generateNPremioPorCategoria } from "../CoreografiaNota/api-coreografia-nota";
import Title from "../UI/Title";
import CoreografiaView from "../Coreografia/CoreografiaView";

const CoreografiaAgenciaEventoContainer = () => {
  const [listCoreografias, setListCoregrafias] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [coreografia, setCoreografia] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [listEventos, setListEventos] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [evento, setEvento] = useState(null);
  const [listEventoDataCategorias, setListEventoDataCategorias] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [eventoDataCategoria, setEventoDataCategoria] = useState(null);
  const coreografiaKey = 'coreografia';
  const participanteKey = 'participante';
  const pagamentoKey = 'pagamento';
  const notaKey = 'nota';
  const notaPremiacaoKey = 'nota-premiacao';
  const [menu, setMenu] = useState(coreografiaKey);
  const [listCoreografiaParticipantePerfis, setListCoreografiaParticipantePerfis] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [listCoreografiaPagamentos, setListCoreografiaPagamentos] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });

  const [listCoreografiaNotaListAudio, setListCoreografiaNotaListAudio] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  })
  const [isLoadingAction, setIsLoadingAction] = useState(false);


  useEffect(() => {
    fetchEventos(0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (evento) {
      fetchEventoDataCategoria(0);
    }
    // eslint-disable-next-line
  }, [evento]);

  useEffect(() => {
    if (eventoDataCategoria) {
      fetchCoreografias(0);
    }
    // eslint-disable-next-line
  }, [eventoDataCategoria]);

  useEffect(() => {
    if (coreografia) {
      fetchCoreografiaParticipantePerfil(0);
      fetchCoreografiaPagamento(0);
      fetchCoreografiaNotaListAudio(0);
    }
    // eslint-disable-next-line
  }, [coreografia]);

  const fetchEventoDataCategoria = (pageNumber) => {
    setIsLoading(true);
    findByEventoCodigo(evento.codigo, pageNumber, size).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListEventoDataCategorias(data);
        }
      }
      setIsLoading(false);
    });
  };

  const fetchEventos = (pageNumber) => {
    setIsLoading(true);
    page(pageNumber, size).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListEventos(data);
        }
      }
      setIsLoading(false);
    });
  };

  const handleCoreografiaChangePage = (event, newPage) => {
    fetchCoreografias(newPage);
  };

  const handleEventoDataCategoriaChangePage = (event, newPage) => {
    fetchEventoDataCategoria(newPage);
  };

  const handleChangePageCoreografiaParticipantePerfil = (event, newPage) => {
    fetchCoreografiaParticipantePerfil(newPage);
  };

  const handleChangePageCoreografiaPagamento = (event, newPage) => {
    fetchCoreografiaPagamento(newPage);
  };

  const fetchCoreografiaParticipantePerfil = (newPage) => {
    setIsLoading(true);
    findByCoreografiaCodigo(coreografia.codigo, newPage, size).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListCoreografiaParticipantePerfis(data);
        }
      }
      setIsLoading(false);
    })
  }

  const fetchCoreografiaPagamento = (newPage) => {
    setIsLoading(true);
    findCoreografiaPagamentoByCoreografiaCodigo(coreografia.codigo, newPage, size).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListCoreografiaPagamentos(data);
        }
      }
      setIsLoading(false);
    })
  }

  const fetchCoreografiaNotaListAudio = () => {
    setIsLoading(true);
    findCoreografiaNotaListAudioByCoreografiaCodigo(coreografia.codigo).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListCoreografiaNotaListAudio(data);
        }
      }
      setIsLoading(false);
    })
  }

  const fetchCoreografias = (newPage) => {
    setIsLoading(true);
    findByEventoDataCategoriaCodigo(
      eventoDataCategoria.codigo,
      newPage,
      size
    ).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListCoregrafias(data);
        }
      }
      setIsLoading(false);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
  };

  const handleEventoChangeRowsPerPage = (event) => {
    setSize(event.target.value);
  };

  const handleEventoChangePage = (event, newPage) => {
    fetchEventos(newPage);
  };

  const handleTrocaEvento = () => {
    setEvento(null);
  };

  const handleTrocaEventoDataCategoria = () => {
    setEventoDataCategoria(null);
  };

  const handleTrocaCoreografia = () => {
    setCoreografia(null);
  }

  const handleGeneratePremioPorCategoria = () => {
    setIsLoadingAction(true);
    generateNPremioPorCategoria(eventoDataCategoria.codigo).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          toast.success("Operação Realizada com Sucesso.");
        }
      }
      setIsLoadingAction(false);

    })
  }


  const handleGenerateNotaFinalPorCoreografia = () => {
    setIsLoadingAction(true);
    generateNotaFinalPorCoreografia(coreografia.codigo).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          toast.success("Operação Realizada com Sucesso.");
          findCoreografiaByCodigo(coreografia.codigo).then((data) => {
            if (data) {
              if (data.error) {
                errorMessage(data.message);
              } else {
                setListCoregrafias((prevState) => ({
                  ...prevState,
                  content: prevState.content.map((ps) => {
                    if (ps.codigo === coreografia.codigo) {
                      return data;
                    } else {
                      return ps;
                    }
                  })
                }));
                setCoreografia(data);
              }
            }
          })
        }
      }
      setIsLoadingAction(false);
    })

  }

  const handleAprovarDesaprovarSeletiva = () => {
    const persist = {
      ...coreografia,
      aprovadoSeletiva: coreografia.aprovadoSeletiva !== null ? !coreografia.aprovadoSeletiva : true
    }
    setIsLoadingAction(true);
    saveCoreografia(persist).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListCoregrafias((prevState) => ({
            ...prevState,
            content: prevState.content.map((ps) => {
              if (ps.codigo === persist.codigo) {
                return persist;
              } else {
                return ps;
              }
            })
          }))
          setCoreografia(data);
        }
      }
      setIsLoadingAction(false);
    })
  }


  return (
    <Grid container spacing={1}>

      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Grid item xs={12}>
            <Title>Coreografia - Agencia Evento</Title>
          </Grid>
          {evento ? (
            <>
              {eventoDataCategoria ? (
                <>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      {coreografia ? (
                        <>
                          <Grid item>
                            <CoreografiaProfile
                              coreografia={coreografia}
                              handleTrocaCoreografia={handleTrocaCoreografia}
                            />
                          </Grid>
                          <Grid item sx={{ my: 3 }}>
                            <CoreografiaAgenciaEventoMenu
                              setMenu={setMenu}
                              pagamentoKey={pagamentoKey}
                              participanteKey={participanteKey}
                              coreografiaKey={coreografiaKey}
                              notaKey={notaKey}
                              notaPremiacaoKey={notaPremiacaoKey}
                            />
                          </Grid>
                          {menu === coreografiaKey &&
                            <Grid item xs={12}>
                              <CoreografiaView
                                coreografia={coreografia}
                                handleAprovarDesaprovarSeletiva={handleAprovarDesaprovarSeletiva}
                                isLoadingAction={isLoadingAction}
                              />
                            </Grid>
                          }
                          {menu === participanteKey &&
                            <Grid item xs={12}>
                              <CoreografiaParticipantePerfilTableView
                                listCoreografiaParticipantePerfis={listCoreografiaParticipantePerfis}
                                handleChangePage={handleChangePageCoreografiaParticipantePerfil}
                                isLoading={isLoading}
                                size={size}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                coreografia={coreografia}
                              />
                            </Grid>
                          }
                          {menu === pagamentoKey &&
                            <Grid item xs={12}>
                              <CoreografiaPagamentoTableView
                                listCoreografiaPagamentos={listCoreografiaPagamentos}
                                handleChangePage={handleChangePageCoreografiaPagamento}
                                isLoading={isLoading}
                                size={size}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                              />
                            </Grid>
                          }
                          {menu === notaKey &&
                            <Grid item xs={12}>
                              <CoreografiaNotaAudioTableView
                                listCoreografiaNotaListAudio={listCoreografiaNotaListAudio}
                                isLoading={isLoading}
                              />
                            </Grid>
                          }
                          {menu === notaPremiacaoKey &&
                            <Grid item xs={12}>
                              <CoreografiaNotaPremiacao
                                coreografia={coreografia}
                                handleGenerateNotaFinalPorCoreografia={handleGenerateNotaFinalPorCoreografia}
                                handleGeneratePremioPorCategoria={handleGeneratePremioPorCategoria}
                                isLoadingAction={isLoadingAction}
                              />
                            </Grid>
                          }

                        </>
                      ) : (
                        <>
                          <Grid item sx={{ display: "flex" }}>
                            <EventoDataCategoriaProfile
                              eventoDataCategoria={eventoDataCategoria}
                              handleTrocaEventoDataCategoria={
                                handleTrocaEventoDataCategoria
                              }
                            />
                          </Grid>
                          <CoreografiaTableSelectView
                            listCoreografias={listCoreografias}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            size={size}
                            handleCoreografiaChangePage={handleCoreografiaChangePage}
                            setCoreografia={setCoreografia}
                            isLoading={isLoading}
                          />

                        </>

                      )}
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid container direction="column" spacing={2}>
                    <Grid item sx={{ display: "flex" }}>
                      <EventoProfile
                        evento={evento}
                        handleTrocaEvento={handleTrocaEvento}
                      />
                    </Grid>
                    <Grid item>
                      <EventoDataCategoriaTableSelectViewVersion2
                        listEventoDataCategorias={listEventoDataCategorias}
                        handleChangePage={handleEventoDataCategoriaChangePage}
                        isLoading={isLoading}
                        size={size}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        setEventoDataCategoria={setEventoDataCategoria}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          ) : (
            <>
              <EventoTableSelectView
                listEventos={listEventos}
                handleEventoChangeRowsPerPage={handleEventoChangeRowsPerPage}
                sizeEvento={size}
                handleEventoChangePage={handleEventoChangePage}
                setEvento={setEvento}
                isLoadingEvento={isLoading}
              />
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CoreografiaAgenciaEventoContainer;
